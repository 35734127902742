export const adminKey = 'admin_key_tallos'

/**
   * Encurtar string de descrições
   *
   * @param desc
   * @param n
   * @param end string
   *
   * @returns {string}
   */
export const truncate = ( desc, n, end = '...') => {
    return (desc.length > n) ? desc.substr(0, n - 1) + end : desc;
}

/**
 * Formatar url S3.
 *
 * @param segment
 *
 * @return {string}
 */
export const urlS3 = (segment = '') => {
    return `${process.env.VUE_APP_URL_S3 + segment}`
}